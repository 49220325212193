import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Link, Strong, Input, Button } from "@quarkly/widgets";
import QuarklycommunityKitForm from "./QuarklycommunityKitForm";
const defaultProps = {
	"width": "100%",
	"flex-direction": "column",
	"justify-content": "flex-start",
	"align-items": "flex-start",
	"grid-gap": "140px",
	"display": "inline-flex",
	"md-display": "flex",
	"md-padding": "56px 16px 24px 16px",
	"md-height": "min-content",
	"md-grid-row-gap": "64px",
	"padding": "100px 16px 24px 16px",
	"background": "white"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"align-self": "stretch",
			"justify-content": "flex-start",
			"align-items": "flex-start",
			"grid-gap": "80px",
			"display": "inline-flex",
			"md-display": "flex",
			"md-flex-direction": "column",
			"md-flex-wrap": "no-wrap",
			"md-align-items": "center",
			"md-justify-content": "center",
			"md-grid-row-gap": "24px",
			"max-width": "1348px",
			"margin": "0px auto 0px auto",
			"width": "100%"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"flex": "1 1 0",
			"flex-direction": "column",
			"justify-content": "flex-start",
			"align-items": "flex-start",
			"grid-gap": "28px",
			"display": "inline-flex",
			"md-width": "100%",
			"md-padding": "0px 0px 0px 0px",
			"md-height": "fit-content",
			"md-align-items": "center",
			"md-display": "flex",
			"md-flex-direction": "column",
			"md-justify-content": "center",
			"md-flex-wrap": "no-wrap",
			"md-grid-column-gap": "0px",
			"md-grid-row-gap": "8px",
			"md-max-width": "460px"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"align-self": "stretch",
			"flex-direction": "column",
			"justify-content": "flex-start",
			"align-items": "flex-start",
			"grid-gap": "24px",
			"display": "flex"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"color": "#080808",
			"font": "600 72px Epilogue",
			"line-height": "76px",
			"word-wrap": "break-word",
			"margin": "0px",
			"md-font": "600 28px/32px Epilogue",
			"md-width": "100%",
			"text-fill-color": "transparent",
			"style": {
				"-webkit-text-fill-color": "transparent"
			},
			"background": "linear-gradient(96.04deg, #080808 -0.01%, #FC0D1D 42.34%)",
			"background-clip": "text",
			"max-width": "100%",
			"width": "350px",
			"children": "Get in touch..."
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"align-self": "stretch",
			"opacity": 0.8,
			"color": "#080808",
			"font": "400 21px Epilogue",
			"line-height": "32px",
			"word-wrap": "break-word",
			"margin": "0px 0px 0px 0px",
			"md-width": "100%",
			"md-font": "400 17px/26px Epilogue",
			"children": <Strong>
				<Link href="mailto:info@sphotonix.com?Web Enquiry: ">
					info@sphotonix.com
				</Link>
			</Strong>
		}
	},
	"quarklycommunityKitForm": {
		"kind": "QuarklycommunityKitForm",
		"props": {
			"flex-direction": "column",
			"justify-content": "flex-start",
			"align-items": "flex-start",
			"grid-gap": "16px",
			"display": "inline-flex",
			"md-width": "100%",
			"max-width": "460px",
			"sm-max-width": "100%",
			"action": "https://hook.us2.make.com/vmpi9qw5hahp757juwr9sws9xf0n4k2u",
			"method": "post"
		}
	},
	"quarklycommunityKitFormOverride": {
		"kind": "Override",
		"props": {
			"slot": "Content",
			"display": "flex",
			"flex-direction": "column",
			"flex-wrap": "no-wrap",
			"grid-row-gap": "16px"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"align-self": "stretch",
			"color": "#212121",
			"font": "400 17px Epilogue",
			"line-height": "26px",
			"word-wrap": "break-word",
			"margin": "0px 0px 0px 0px",
			"md-font": "400 17px/26px Epilogue",
			"children": "Stay ahead with the latest breakthroughs in optical nanotechnology! Discover how SPhotonix is revolutionizing data storage and optical innovation. Join our community for exclusive insights and updates!"
		}
	},
	"input": {
		"kind": "Input",
		"props": {
			"display": "inline-flex",
			"placeholder-opacity": 0.4,
			"placeholder-color": "#080808",
			"placeholder-font": "400 17px Epilogue",
			"placeholder-line-height": "26px",
			"placeholder-word-wrap": "break-word",
			"width": "100%",
			"padding-left": "24px",
			"padding-right": "24px",
			"padding-top": "16px",
			"padding-bottom": "16px",
			"border-radius": "8px",
			"overflow": "hidden",
			"justify-content": "flex-start",
			"align-items": "flex-start",
			"grid-gap": "10px",
			"md-width": "100%",
			"placeholder": "Name",
			"border-color": "#B1B1B1",
			"border-width": "1px",
			"border-style": "solid",
			"focus-border-color": " #B1B1B1",
			"background": "none",
			"name": "name",
			"autocomplete": "name",
			"type": "text"
		}
	},
	"input1": {
		"kind": "Input",
		"props": {
			"display": "inline-flex",
			"placeholder-opacity": 0.4,
			"placeholder-color": "#080808",
			"placeholder-font": "400 17px Epilogue",
			"placeholder-line-height": "26px",
			"placeholder-word-wrap": "break-word",
			"width": "100%",
			"padding-left": "24px",
			"padding-right": "24px",
			"padding-top": "16px",
			"padding-bottom": "16px",
			"border-radius": "8px",
			"overflow": "hidden",
			"justify-content": "flex-start",
			"align-items": "flex-start",
			"grid-gap": "10px",
			"md-width": "100%",
			"placeholder": "Email",
			"border-color": "#B1B1B1",
			"border-width": "1px",
			"border-style": "solid",
			"focus-border-color": " #B1B1B1",
			"required": true,
			"background": "none",
			"name": "email",
			"autocomplete": "email",
			"type": "email"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"width": "100%",
			"padding-left": "24px",
			"padding-right": "24px",
			"padding-top": "16px",
			"padding-bottom": "16px",
			"background": "#EB1E1E",
			"border-radius": "8px",
			"grid-gap": "10px",
			"color": "white",
			"font": "600 17px Epilogue",
			"line-height": "26px",
			"word-wrap": "break-word",
			"text-align": "center",
			"type": "submit",
			"children": "Subscibe to Newsletter"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"align-self": "stretch",
			"height": "51px",
			"flex-direction": "column",
			"justify-content": "flex-start",
			"align-items": "flex-start",
			"grid-gap": "24px",
			"display": "flex",
			"md-display": "flex",
			"md-grid-row-gap": "32px",
			"md-height": "auto",
			"max-width": "1348px",
			"margin": "0px auto 0px auto",
			"width": "100%"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"align-self": "stretch",
			"height": "1px",
			"background": "#B1B1B1",
			"md-width": "100%",
			"md-height": "1px"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"align-self": "center",
			"justify-content": "space-between",
			"align-items": "center",
			"grid-gap": "32px",
			"display": "flex",
			"md-width": "100%",
			"md-height": "fit-content",
			"md-display": "flex",
			"md-flex-direction": "row",
			"md-flex-wrap": "wrap",
			"md-align-content": "space-around",
			"md-align-items": "center",
			"md-justify-content": "space-between",
			"flex-direction": "row",
			"md-grid-row-gap": "16px"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"color": "#212121",
			"font": "400 17px Epilogue",
			"line-height": "26px",
			"word-wrap": "break-word",
			"margin": "0px 0px 0px 0px",
			"children": "©2024 SPhotonix. All rights reserved."
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "none",
			"flex-direction": "row",
			"flex-wrap": "no-wrap",
			"align-items": "center",
			"justify-content": "center",
			"grid-column-gap": "32px",
			"md-order": "-1"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"color": "#212121",
			"font": "400 17px Epilogue",
			"line-height": "26px",
			"word-wrap": "break-word",
			"margin": "0px 0px 0px 0px",
			"children": "Privacy Policy"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"color": "#212121",
			"font": "400 17px Epilogue",
			"line-height": "26px",
			"word-wrap": "break-word",
			"margin": "0px 0px 0px 0px",
			"children": "Cookies"
		}
	}
};

const FooterWithForm2 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Box {...rest}>
		{"    "}
		<Box {...override("box")}>
			{"    "}
			<Box {...override("box1")}>
				{"      "}
				<Box {...override("box2")}>
					{"        "}
					<Text {...override("text")} />
					{"      "}
				</Box>
				{"      "}
				<Text {...override("text1")} />
				{"    "}
			</Box>
			{"    "}
			<QuarklycommunityKitForm {...override("quarklycommunityKitForm")}>
				<Override {...override("quarklycommunityKitFormOverride")} />
				{"      "}
				<Text {...override("text2")} />
				{"      "}
				<Input {...override("input")} />
				<Input {...override("input1")} />
				{"      "}
				<Button {...override("button")}>
					Subscibe to Newsletter
				</Button>
				{"    "}
			</QuarklycommunityKitForm>
			{"  "}
		</Box>
		{"  "}
		<Box {...override("box3")}>
			{"    "}
			<Box {...override("box4")} />
			{"    "}
			<Box {...override("box5")}>
				{"      "}
				<Text {...override("text3")} />
				<Box {...override("box6")}>
					<Text {...override("text4")} />
					<Text {...override("text5")} />
				</Box>
				{"                "}
			</Box>
			{"  "}
		</Box>
		{children}
	</Box>;
};

Object.assign(FooterWithForm2, { ...Box,
	defaultProps,
	overrides
});
export default FooterWithForm2;